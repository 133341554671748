



























































import { Component, Prop, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import ProjectDurationInfoComponent from "@/components/project/ProjectDurationInfoComponent.vue";
@Component({
  components: {
    Header,
    ProjectDurationInfoComponent,
  },
})
export default class ProjectDurationInfo extends Vue {
  @Prop({
    default: "bag-icon.svg",
    type: String,
  })
  private icon!: string;

  @Prop({
    default: "Personal Leave",
    type: String,
  })
  private name!: string;

  @Prop({
    default: 0,
    type: Number,
  })
  private approvedCount!: number;

  @Prop({
    default: 0,
    type: Number,
  })
  private limit!: number;

  @Prop({
    default: 0,
    type: Number,
  })
  private rejectedCount!: number;

  @Prop({
    default: 0,
    type: Number,
  })
  private pendingCount!: number;

  private get leftOverTime() {
    // + will remove trailing zeroes
    return +Math.max(0, this.limit - (this.approvedCount + this.pendingCount)).toFixed(2);
  }
}
