
























import { Component, Ref, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { ProjectPositionInterface, ProjectInterface } from "@/types/project.type";
import { ProjectModule } from "@/store/modules/project-module";
import { DialogInterface } from "@/types/component.type";
import { secondToDay } from "@/utils/unitConverstion";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import TableComponent from "@/components/TableComponent.vue";
import SearchField from "@/components/text-field/SearchField.vue";
import Header from "@/components/Header.vue";

@Component({
  components: {
    TableComponent,
    SearchField,
    Header,
  },
})
export default class ProjectPositions extends DurationHelperMixins {
  @Ref()
  private readonly dialog!: DialogInterface;

  private headers: DataTableHeader[] = [
    {
      text: "Position",
      value: "name",
    },
    {
      text: "Total duration limit",
      value: "sumDurationLimit",
    },
    {
      text: "Total position charge",
      value: "sumPositionCharge",
    },
  ];

  private projectPosition: ProjectPositionInterface | null = null;

  private search: string = "";

  get project(): ProjectInterface {
    return ProjectModule.project;
  }

  get projectPositions(): ProjectPositionInterface[] {
    return ProjectModule.projectPositions;
  }
  public secondToDay(sec: number) {
    return secondToDay(sec);
  }

  private updateSearch(txt: string) {
    this.search = txt;
  }
}
