




















































import { Component, Ref, Mixins, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { ProjectMemberModule } from "@/store/modules/project-member-module";
import { secondToDay } from "@/utils/unitConverstion";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import { DialogInterface } from "@/types/component.type";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { ProjectMemberInterface } from "@/types/project-member.type";
import { RoleMixin } from "@/mixins/role-mixin";
import SearchField from "@/components/text-field/SearchField.vue";
import Header from "@/components/Header.vue";
import CreateButton from "@/components/buttons/CreateButton.vue";
import TableComponent from "@/components/TableComponent.vue";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";

@Component({
  components: {
    ConfirmationDialog,
    SearchField,
    Header,
    CreateButton,
    TableComponent,
  },
})
export default class ProjectMembers extends Mixins(DurationHelperMixins, RoleMixin) {
  private headers: DataTableHeader[] = [
    {
      text: "Name",
      value: "userDisplay",
    },
    {
      text: "Position",
      value: "positionDisplay",
    },
    {
      text: "Maximum Duration",
      value: "maximumUnit",
    },
    {
      text: "Approved Duration",
      value: "totalDuration",
    },
    {
      text: "Action",
      value: "action",
    },
  ];
  private projectMemberId: number | null = null;

  private search: string = "";

  @Ref()
  private readonly deleteDialog!: DialogInterface;

  @Prop({
    default: true,
    type: Boolean,
  })
  private readonly canEdit!: boolean;

  get members() {
    return ProjectMemberModule.projectMembers;
  }
  get isManager(): boolean {
    return ProjectMemberModule.isManager;
  }
  public async created() {
    await ProjectMemberModule.getMembersByProjectId(Number(this.$route.params.id));
    await ProjectMemberModule.getIsManager(Number(this.$route.params.id));
  }
  public createNewMember(): void {
    this.$router.push({
      name: "New Member",
      params: { id: this.$route.params.id!.toString() },
    });
  }
  public editProjectMember(userId: number): void {
    this.$router.push(`/project/${this.$route.params.id}/member/${userId}/`);
  }
  public secondToDay(sec: number) {
    return secondToDay(sec);
  }

  private openDeleteProjectMemberDialog(id: number) {
    this.projectMemberId = id;
    this.deleteDialog.openDialog();
  }

  private async deleteProjectMember() {
    try {
      await ProjectMemberModule.deleteProjectMember(this.projectMemberId!);
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete member successfully!",
    });
  }

  private updateSearch(txt: string) {
    this.search = txt;
  }

  private goToRowDetail(data: ProjectMemberInterface) {
    if (this.canEdit) {
      this.$router.push(`/project/${this.$route.params.id}/member/${data.id}/`);
    }
  }
}
