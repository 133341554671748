
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ExportButton extends Vue {
  @Prop({
    type: Function,
  })
  private click!: () => void;

  @Prop({
    type: String,
    default: "Export File CSV",
  })
  private readonly text!: string;
}
