
































































import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { ProjectInterface } from "@/types/project.type";
import { ProjectModule } from "@/store/modules/project-module";
import QuotationModule from "@/store/modules/quotation-module";
import { getDurationTypeDisplay } from "@/utils/duration";
import { DurationTypeModule } from "@/store/modules/duration-module";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import ProjectQuotationForm from "@/components/project/ProjectQuotationForm.vue";
import { QuotationInterface } from "@/types/quotation.type";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { RoleMixin } from "@/mixins/role-mixin";
import { DateConverterMixin } from "@/mixins/date-converter-mixin";
import { ProjectMemberModule } from "@/store/modules/project-member-module";
import Header from "@/components/Header.vue";
import SearchField from "@/components/text-field/SearchField.vue";
import CreateButton from "@/components/buttons/CreateButton.vue";
import TableComponent from "@/components/TableComponent.vue";
import Dialog from "@/components/Dialog.vue";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";

@Component({
  components: {
    ProjectQuotationForm,
    ConfirmationDialog,
    Header,
    SearchField,
    CreateButton,
    TableComponent,
    Dialog,
  },
})
export default class ProjectQuotations extends Mixins(DurationHelperMixins, RoleMixin, DateConverterMixin) {
  public $refs!: {
    quotationForm: HTMLFormElement;
    deleteDialog: HTMLFormElement;
    dialog: HTMLFormElement;
  };

  @Prop({
    type: [Number, String],
    default: null,
  })
  private readonly projectId!: number | 0;

  get quotationHeader(): DataTableHeader[] {
    return QuotationModule.quotationHeader;
  }

  get project(): ProjectInterface {
    return ProjectModule.project;
  }

  get isManager(): boolean {
    return ProjectMemberModule.isManager;
  }

  private search: string = "";

  private getDurationTypeDisplay = getDurationTypeDisplay;

  private quotationId: number | null | string = 1;

  private editModeStatus: boolean = false;

  private quotationDialog: boolean = false;

  private quotationForm: object = {};

  private quotationFormDefault: QuotationInterface = {
    project: this.projectId,
    number: "",
    serviceNumber: "",
    positions: [],
    fake: false,
  };

  @Watch("quotationDialog")
  private quotationDialogChanged(val: boolean) {
    if (!val) {
      this.closeDialog();
    }
  }

  private updateSearch(txt: string) {
    this.search = txt;
  }

  private async created() {
    await DurationTypeModule.getDurationTypes();
    this.quotationForm = Object.assign({}, this.quotationFormDefault);
    await ProjectMemberModule.getIsManager(Number(this.$route.params.id));
  }

  private closeDialog() {
    this.$refs.dialog.simpleCloseDialog();
    setTimeout(() => {
      this.quotationForm = Object.assign({}, this.quotationFormDefault);
      this.editModeStatus = false;
      const resetAllValidation = this.$refs.quotationForm.$refs.form;
      resetAllValidation.resetValidation();
    }, 300);
  }

  private closeEditDialog() {
    this.$refs.dialog.simpleCloseDialog();
  }

  private openEditQuotationDialog(quotation: object) {
    this.editModeStatus = true;
    this.quotationForm = Object.assign({}, quotation);
    this.$refs.dialog.openDialog();
  }

  private openQuotationDialog() {
    this.editModeStatus = false;
    this.$refs.dialog.openDialog();
  }

  private openDeleteQuotationDialog(id: number) {
    this.quotationId = id;
    this.$refs.deleteDialog.openDialog();
  }

  private async deleteQuotation() {
    try {
      await QuotationModule.deleteQuotation(this.quotationId as number);
      await ProjectModule.getProjectById(this.projectId as number);
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete quotation successfully!",
    });
  }

  private goToNewQuotation(): void {
    this.$router.push({
      name: "Quotation",
      params: { id: this.$route.params.id },
    });
  }

  private goToQuotationDetail(quotationId: number): void {
    this.$router.push({
      name: "Quotation Detail",
      params: { uid: quotationId!.toString() },
    });
  }

  private goToRowDetail(data: QuotationInterface) {
    this.$router.push({
      name: "Quotation Detail",
      params: { uid: data.id!.toString() },
    });
  }
}
