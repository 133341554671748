






















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ProjectDurationInfoComponent extends Vue {
  @Prop({
    default: "Loading",
    type: String,
  })
  private name!: string;

  @Prop({
    default: 0,
    type: Number,
  })
  private count!: number;

  @Prop({
    default: "",
    type: String,
  })
  private icon!: string;

  @Prop({
    default: "",
    type: String,
  })
  private imageAlt!: string;

  @Prop({
    default: "",
    type: String,
  })
  private miscText!: string;
}
