





































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { TimesheetInterface } from "@/types/timesheet.type";
import { mixins } from "vue-class-component";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import { JSONToCSVMixin } from "@/mixins/JSONToCSV-mixin";
import { TimeSheetTableForProject } from "@/types/timesheet-table.type";
import TimeSheetForm from "@/components/time-sheet/TimeSheetForm.vue";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { TimesheetModule } from "@/store/modules/timesheet-module";
import { DurationTypeInterface } from "@/types/durationType.type";
import { DurationTypeModule } from "@/store/modules/duration-module";
import { ProjectModule } from "@/store/modules/project-module";
import { ProjectMemberModule } from "@/store/modules/project-member-module";
import { RoleMixin } from "@/mixins/role-mixin";
import Header from "@/components/Header.vue";
import SearchField from "@/components/text-field/SearchField.vue";
import ExportButton from "@/components/buttons/ExportButton.vue";
import TableComponent from "@/components/TableComponent.vue";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import { updateTimesheetsStatus } from "@/services/timesheet.service";
import { NotificationModule } from "@/store/modules/notification-module";
import { DataTableOption } from "@/types/data-table.type";
import { ProjectTagInterface } from "@/types/project.type";

@Component({
  components: {
    DeleteButton,
    TimeSheetForm,
    ConfirmationDialog,
    Header,
    SearchField,
    ExportButton,
    TableComponent,
    SaveButton,
  },
})
export default class TimeSheetProject extends mixins(DurationHelperMixins, JSONToCSVMixin, RoleMixin) {
  private get displayTimeSheet() {
    const timesheets = [...this.timesheets];

    return timesheets.map((t) => {
      const ret = {} as TimeSheetTableForProject;
      ret.lock = t.lock;
      ret.status = t.status;
      ret.date = t.date;
      ret.taskDescription = t.taskDescription;
      ret.duration = t.duration;
      ret.userDisplay = t.userDisplay;
      ret.projectDisplay = t.projectDisplay;
      ret.billable = t.billable;
      ret.projectTags = t.projectTags as ProjectTagInterface[];
      ret.id = t.id;
      return ret;
    });
  }

  get durationTypes(): DurationTypeInterface[] {
    return DurationTypeModule.durationTypes;
  }

  get timesheets(): TimesheetInterface[] {
    return TimesheetModule.timesheetsByProject;
  }

  get isManager(): boolean {
    return ProjectMemberModule.isManager;
  }

  get timesheetCount(): number {
    return TimesheetModule.totalTimesheet;
  }

  get rejectText(): string {
    let text = "Reject";
    if (this.selected.length > 0) {
      text += ` (${this.selected.filter((data: any) => !data.lock).length})`;
    }
    return text;
  }

  get approveText(): string {
    let text = "Approve";
    if (this.selected.length > 0) {
      text += ` (${this.selected.filter((data: any) => !data.lock).length})`;
    }
    return text;
  }

  get statusList(): any {
    return [
      { text: "All", val: "AL" },
      { text: "Pending", val: "PD" },
      { text: "Approved", val: "AP" },
      { text: "Rejected", val: "RJ" },
    ];
  }

  public timeSheetDialog = false;
  public timeSheetId = -1;
  public $refs!: {
    deleteDialog: HTMLFormElement;
    approveDialog: HTMLFormElement;
    rejectDialog: HTMLFormElement;
    timesheetForm: HTMLFormElement;
  };

  @Prop({ required: false, default: false })
  public admin: boolean | undefined;

  private selected: [] = [];
  private statusFilter: string = "AL";

  @Prop({ required: true })
  private readonly projectId!: number;

  private headers: DataTableHeader[] = [
    {
      text: "Lock",
      value: "lock",
      width: "5%",
    },
    {
      text: "Approved",
      value: "status",
      width: "10%",
    },
    {
      text: "Date",
      value: "date",
      width: "10%",
    },
    {
      text: "Billable",
      value: "billable",
      width: "10%",
    },
    {
      text: "Description",
      value: "taskDescription",
    },
    {
      text: "Tags",
      value: "projectTags",
    },
    {
      text: "Duration",
      value: "duration",
    },
    {
      text: "Member",
      value: "userDisplay",
    },
    {
      text: "Action",
      value: "action",
    },
  ];

  private search: string = "";

  private options?: DataTableOption;

  private timer?: NodeJS.Timeout;

  private exportCSV() {
    this.turnProjectTimeSheetTabJSONToCSV(this.displayTimeSheet, this.headers);
  }

  private optionChanged(newOptions: DataTableOption) {
    this.options = newOptions;
    this.reload();
  }

  private openTimeSheetDialog(id: number) {
    this.timeSheetDialog = true;
    this.timeSheetId = id;
  }

  private async closeDialog(success: boolean, responseText: string) {
    if (success) {
      await SnackbarModule.setSnack({
        color: "success",
        message: responseText,
      });
      await this.reload();
    } else {
      await SnackbarModule.setSnack({
        color: "error",
        message: responseText,
      });
    }
    this.timeSheetDialog = false;
  }

  private async deleteTimesheet() {
    this.$refs.deleteDialog.closeDialog();
    try {
      await TimesheetModule.deleteTimesheet(this.timeSheetId);
      await this.reload();
    } catch (exp) {
      const error = exp.toJSON();
      await SnackbarModule.setSnack({
        color: "error",
        message: error.message,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete timesheet successfully!",
    });
  }

  private setCurrentSelectTimesheet(id: number) {
    this.timeSheetId = id;
    this.$refs.deleteDialog.openDialog();
  }

  private async mounted() {
    await ProjectModule.getProjectList(false);
    await DurationTypeModule.getDurationTypes();
    await ProjectMemberModule.getIsManager(Number(this.$route.params.id));
  }

  private async reload() {
    await TimesheetModule.getTimesheetsByProject({
      id: this.projectId,
      options: this.options,
      status: this.statusFilter,
      search: this.search,
    });
  }

  private clickedRow(data: any) {
    this.openTimeSheetDialog(data.id);
  }

  private updateSearch(txt: string) {
    this.search = txt;
  }

  private updateSelected(selected: []) {
    this.selected = selected;
  }

  private async rejectRequest() {
    await updateTimesheetsStatus(
      this.selected.map((dat: any) => Number(dat.id)),
      "RJ"
    );
    await this.reload();
    await NotificationModule.getProjectNotificationCount();
    this.$refs.rejectDialog.closeDialog();
  }

  private async approveRequest() {
    await updateTimesheetsStatus(
      this.selected.map((dat: any) => Number(dat.id)),
      "AP"
    );
    await this.reload();
    await NotificationModule.getProjectNotificationCount();
    this.$refs.approveDialog.closeDialog();
  }

  private closeEditDialog(isClose: boolean) {
    this.timeSheetDialog = isClose;
    setTimeout(() => {
      const refTimesheet = this.$refs.timesheetForm;
      const resetAllValidation = refTimesheet.$refs.form as Vue & {
        resetValidation: () => boolean;
      };
      resetAllValidation.resetValidation();
    }, 300);
  }

  @Watch("statusFilter")
  private watchStatusFilter() {
    if (this.options) {
      this.options.page = 1;
    }
    this.reload();
  }

  @Watch("search")
  private searchChange(newVal: string) {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    const vm = this;

    this.timer = setTimeout(() => {
      if (this.options) {
        this.options.page = 1;
      }
      vm.reload();
    }, 1000);
  }
}
