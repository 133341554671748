




































































import { Component, Prop, Watch } from "vue-property-decorator";
import ProjectPositions from "@/components/project/ProjectPositions.vue";
import ProjectQuotations from "@/components/project/ProjectQuotations.vue";
import ProjectMembers from "@/components/project/ProjectMembers.vue";
import ProjectBreadcrumb from "@/components/project/ProjectBreadcrumb.vue";
import ProjectDurationInfo from "@/components/project/ProjectDurationInfo.vue";
import { ProjectModule } from "@/store/modules/project-module";
import { ProjectInterface } from "@/types/project.type";
import { mixins } from "vue-class-component";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import TimeSheetProject from "@/components/project/TimeSheetProject.vue";
import { TimesheetModule } from "@/store/modules/timesheet-module";
import { ErrorModule } from "@/store/modules/error";
import NotFound from "@/components/NotFound.vue";
import { ProjectMemberModule } from "@/store/modules/project-member-module";
import EditButton from "@/components/buttons/EditButton.vue";
import Header from "@/components/Header.vue";
import { humanizeDuration } from "@/utils/unitConverstion";

@Component({
  components: {
    ProjectPositions,
    ProjectMembers,
    ProjectQuotations,
    ProjectBreadcrumb,
    ProjectDurationInfo,
    TimeSheetProject,
    NotFound,
    EditButton,
    Header,
  },
})
export default class ProjectDetail extends mixins(DurationHelperMixins) {
  get timesheets() {
    return TimesheetModule.timesheetsByProject;
  }

  get isNotFound(): boolean {
    return ErrorModule.isNotFound;
  }
  @Prop({
    required: true,
  })
  private readonly id!: number;

  private tabs: string[] = ["Access", "Positions", "Quotations", "TimeSheets"];

  private tabs1: string[] = ["Quotations", "Access"];

  private tabs2: string[] = ["Positions", "TimeSheets"];
  private tabs2Alert: boolean[] = [false, true];

  public async mounted() {
    await ProjectMemberModule.getIsManager(Number(this.$route.params.id));
  }

  private async created() {
    await ProjectModule.getProjectById(this.id);
  }

  get totalDuration() {
    return parseFloat(humanizeDuration(this.projectData.totalDuration, true));
  }

  get approvedDuration() {
    return parseFloat(humanizeDuration(this.projectData.approvedDuration, true));
  }

  get pendingDuration() {
    return parseFloat(humanizeDuration(this.projectData.pendingDuration, true));
  }

  get rejectedDuration() {
    return parseFloat(humanizeDuration(this.projectData.rejectedDuration, true));
  }

  get projectData() {
    return {
      ...ProjectModule.project,
    };
  }

  private createNewMember(): void {
    this.$router.push({
      name: "New Member",
      params: { id: this.id.toString() },
    });
  }

  private editProject(): void {
    this.$router.push(`/project/${this.$route.params.id}/setting`);
  }

  @Watch("projectData")
  private projectUpdated(newValue: ProjectInterface) {
    this.tabs2Alert[1] = newValue.alertCount > 0;
  }
}
